import * as React from 'react';
import { Link } from 'gatsby';

import SaxBtnImageWhite from '../../../images/saxmax_steps@2x.png';
import BtnArrow from '../../../images/btn_arrow.svg';
import SaxBtnImageBlack from '../../../images/saxmax_small.png';

const Btn = ({ whiteImage, onClick, children }) => {
  return (
    <div className='buttonContainer'>
      <Link className='btn' to='/customize-your-sax'>
        <img
          className='btnSaxImage'
          src={whiteImage ? SaxBtnImageWhite : SaxBtnImageBlack}
          alt='SaxMax'
          loading='lazy'
        />
        {children}
        <img className='btnArrow' src={BtnArrow} alt='Button Arrow' />
      </Link>
    </div>
  );
};

export default Btn;
